<template>
  <div>
    <h3 class="mb-5 text-secondary">
      {{ $t("page.maintainerIssues.title") }}
      <span
        v-for="category in maintainerCategories"
        :key="category._id"
        class="ml-1 h6"
      >
        <b-badge class="text-white bg-cyan-400 font-weight-normal">
          {{ category.description }}
        </b-badge>
      </span>
    </h3>
    <maintainer-issue-filters
      :issues="formatedIssues"
    ></maintainer-issue-filters>
    <maintainer-issue-table
      class="pt-4"
      :issues="formatedIssues"
      :fields="fields"
      :actions="actions"
      :sortBy.sync="sortBy"
      :sortDesc.sync="sortDesc"
    />
    <edit-issue
      :state.sync="editModalState"
      :issue="selectedIssue"
      :callbackAfterEdit="retrieveIssues"
      @close="closeEditIssueModal"
    />
  </div>
</template>

<script>
import MaintainerIssueFilters from "./../components/MaintainerIssues/Filters.vue";
import MaintainerIssueTable from "./../components/MaintainerIssues/IssuesTable.vue";
import EditIssue from "./../components/Issue/EditIssue";
import { authMixin } from "../mixins";

export default {
  components: {
    MaintainerIssueFilters,
    MaintainerIssueTable,
    EditIssue
  },
  async created() {
    await this.$store.dispatch("parameters/retrieveCategories");
    await this.$store.dispatch("condominium/retrieveCondominiums");
    await this.$store.dispatch("maintainerIssues/retrieveUserIssues");
  },
  data() {
    return {
      editModalState: false,
      fields: [
        { key: "condominiumName", label: "condominium", sort: true },
        { key: "createdAt", label: "date", sort: true },
        { key: "category", label: "category", sort: false },
        { key: "title", label: "issue", sort: true },
        { key: "status", label: "status", sort: false },
        { key: "owner", label: "nameAndSurname", sort: true },
        { key: "actions", label: "" }
      ],
      actions: [
        {
          label: "button.manage",
          callbackFunc: this.openEditIssueModal
        }
      ]
    };
  },
  mixins: [authMixin],
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.$route.params.id) {
          this.editModalState = false;
          this.openEditIssueModal(this.$route.params.id);
        }
      }
    }
  },
  computed: {
    issues() {
      return this.$store.getters["maintainerIssues/getIssues"] || [];
    },
    selectedIssue() {
      return this.$store.getters["maintainerIssues/getCurrentIssue"];
    },
    maintainerCategories() {
      return this.$store.getters["userMaintainer/getCurrentMaintainerUser"]
        .generalInfo.type;
    },
    formatedIssues() {
      return this.issues.map(item => {
        return {
          id: item._id,
          condominiumName: item.condominiumId.name,
          createdAt: item.createdAt,
          title: item.title,
          photos: item.photos,
          totalPhotos: item.photos.length,
          status: item.status,
          type: item.type,
          totalComments: item.comments.filter(comment => comment.ownerId)
            .length,
          owner: item.ownerId,
          reported: item.reported || item.comments.find(c => c.reported),
          category: item.type?.description
        };
      });
    },
    sortBy: {
      get() {
        return this.$store.getters["maintainerIssues/getSortBy"];
      },
      set(value) {
        this.$store.commit("maintainerIssues/SET_SORT_BY", value);
      }
    },
    sortDesc: {
      get() {
        return this.$store.getters["maintainerIssues/getSortDesc"];
      },
      set(value) {
        this.$store.commit("maintainerIssues/SET_SORT_DESC", value);
      }
    }
  },
  methods: {
    async retrieveIssues() {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("maintainerIssues/retrieveUserIssues");
        const selectedIssue = this.issues.find(
          issue => issue._id === this.selectedIssue._id
        );
        this.$store.commit("maintainerIssues/SET_CURRENT_ISSUE", selectedIssue);
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    openEditIssueModal(id) {
      const selectedIssue = this.issues.find(issue => issue._id === id);
      this.$store.commit("maintainerIssues/SET_CURRENT_ISSUE", selectedIssue);
      this.editModalState = true;
    },
    closeEditIssueModal() {
      this.editModalState = false;
      setTimeout(() => {
        if (this.$route.params.id) {
          this.$router.push({ params: { id: null } });
        }
      }, 300);
    }
  }
};
</script>

<style lang="scss" scoped></style>
