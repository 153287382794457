<template>
  <b-row class="no-gutters ml-n3">
    <b-col v-if="!$isDomi" class="col-12 pl-3 pr-5">
      <b-form-group
        :label="$t('page.issue.typeLabel')"
        label-class="font-weight-bold"
      >
        <b-form-checkbox-group v-model="selectedCategories" id="issue-status">
          <b-form-checkbox
            style="width:40%; max-width: 300px;"
            v-for="(category, index) in maintainerCategories"
            :key="index"
            :value="category._id"
          >
            {{ category.description }}
          </b-form-checkbox>
        </b-form-checkbox-group>
      </b-form-group>
    </b-col>
    <b-col class="col-12 col-md-6 col-lg-4 pl-3">
      <search-input
        v-model="searchInput"
        label="search"
        :placeholder="$t('page.maintainerIssues.searchPlaceholder')"
        @search="search"
      ></search-input>
    </b-col>
    <b-col class="col-12 col-md-6 col-lg-4 pl-3">
      <form-select v-model="filterIssueStatus" label="status">
        <b-form-select-option :value="null">
          {{ $t("all") }}
        </b-form-select-option>
        <b-form-select-option
          v-for="(status, index) in issueStatusOptions"
          :key="index"
          :value="status"
        >
          {{ $t("page.maintainerIssues.status." + status) }}
        </b-form-select-option>
      </form-select>
    </b-col>
    <b-col class="col-12 col-md-6 col-lg-4 pl-3">
      <form-select v-model="filterIssuesCondominium" label="condominium">
        <b-form-select-option :value="null">
          {{ $t("all") }}
        </b-form-select-option>
        <b-form-select-option
          v-for="(condominium, index) in condominiums"
          :key="index"
          :value="condominium._id"
        >
          {{ condominium.name }}
        </b-form-select-option>
      </form-select>
    </b-col>
  </b-row>
</template>

<script>
import { appMixin } from "./../../mixins";
import SearchInput from "../Base/BaseSearchInput";
import FormSelect from "../Base/BaseFormSelect";
import { SORT_FILTERS } from "./../../Utils";

export default {
  components: {
    SearchInput,
    FormSelect
  },
  props: {
    issues: {
      type: Array,
      default: () => []
    }
  },
  mixins: [appMixin],
  data() {
    return {
      searchInput: "",
      condominiumsOptions: []
    };
  },
  mounted() {
    this.searchInput = this.filterSearch;
  },
  computed: {
    maintainerCategories() {
      return this.$store.getters["userMaintainer/getCurrentMaintainerUser"]
        .generalInfo.type;
    },
    selectedCategories: {
      get() {
        return this.$store.getters["maintainerIssues/getFilterTypes"];
      },
      set(value) {
        this.$store.commit("maintainerIssues/SET_FILTER_TYPES", value);
        this.retrieveIssues();
      }
    },
    condominiums() {
      return SORT_FILTERS(
        this.$store.getters["condominium/getActiveCondominiums"],
        "name"
      );
    },
    issueStatusOptions() {
      return this.$store.getters["maintainerIssues/getStatusOptions"];
    },
    filterSearch: {
      get() {
        return this.$store.getters["maintainerIssues/getFilterSearch"];
      },
      set(value) {
        this.$store.commit("maintainerIssues/SET_FILTER_SEARCH", value);
      }
    },
    filterIssueStatus: {
      get() {
        return this.$store.getters["maintainerIssues/getFilterStatus"];
      },
      set(value) {
        this.$store.commit("maintainerIssues/SET_FILTER_STATUS", value);
        this.retrieveIssues();
      }
    },
    filterIssuesCondominium: {
      get() {
        return this.$store.getters["maintainerIssues/getFilterCondominium"];
      },
      set(value) {
        this.$store.commit("maintainerIssues/SET_FILTER_CONDOMINIUM", value);
        this.retrieveIssues();
      }
    }
  },
  methods: {
    search() {
      this.filterSearch = this.searchInput;
      this.retrieveIssues();
    },
    async retrieveIssues() {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("maintainerIssues/retrieveUserIssues");
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
